import React, { createContext, useState, useContext, useEffect } from 'react';
import { Dimensions } from 'react-native';

const BackgroundImageContext = createContext();

export const useBackgroundImage = () => useContext(BackgroundImageContext);

export const BackgroundImageProvider = ({ children }) => {
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [backgroundVideos, setBackgroundVideos] = useState([]);
  const [isMobile, setIsMobile] = useState(Dimensions.get('window').width <= 768);

  useEffect(() => {
    const handleDimensionsChange = ({ window }) => {
      setIsMobile(window.width <= 768);
    };

    Dimensions.addEventListener('change', handleDimensionsChange);

    return () => {
      Dimensions.removeEventListener('change', handleDimensionsChange);
    };
  }, []);

  const updateBackgroundMedia = (images = [], videos = []) => {
    console.log('BackgroundImageContext updating media:', {
      images: images.length,
      videos: videos.length,
      videoUrls: videos
    });
    setBackgroundImages(images);
    setBackgroundVideos(videos);
  };

  const contextValue = {
    backgroundImages,
    backgroundVideos,
    updateBackgroundMedia,
    isMobile,
  };

  return (
    <BackgroundImageContext.Provider value={contextValue}>
      {children}
    </BackgroundImageContext.Provider>
  );
};